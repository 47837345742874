<template >
    <main v-show="isReady" class="kb-login">
      <div class="login-cover">
        <img v-if="isMobile" src="@/assets/lxp/mobile/images/login/bg_2.jpg" alt="">
        <video v-else src="@/assets/lxp/videos/login.mp4" autoplay="" muted="muted" loop=""></video>
      </div>
      <div class="login-component">
        <div class="logo">
          <img src="../../assets/lxp/images/login/kb_logo.svg" alt="">
        </div>
        <div class="auth-body">
          <div class="auth-forms">
            <div class="field-area">
              <div class="kb-form-input-group">
                <input type="text" class="kb-form-input" placeholder="접속 대상 아이디 입력"
                       v-model.trim="info.lrnerId"
                       @keyup.enter="enterAdminId"
                >
                <div class="input-box" style="border-radius: 32px;"></div>
              </div>
            </div>

            <div class="field-area">
              <div class="kb-form-input-group">
                <input type="text" class="kb-form-input" placeholder="운영자 직원번호 입력"
                       v-model.trim="info.adminId"
                       @keyup.enter="login"
                       style="color: #FFFFFF"
                       id="enterAdminIdEle"
                >
                <div class="input-box" style="border-radius: 32px;border-color: rgba(255,255,255,.1); background-color: rgba(0,0,0,0.2)"></div>
              </div>
            </div>
          </div>

          <button v-if="certFlag" class="kb-btn kb-btn-primary kb-btn-lg kb-btn-login"
                  :class="{'gray-bg-Btn': isLoading, 'white-bg-Btn': isLoading}"
                  @click="login"
                  style="border-radius: 32px">
            <span class="text">로그인</span>
          </button>

        </div>
      </div>
    </main>
    <!-- end::kb-login -->
    <!-- begin::kb-footer -->
<!--    <footer class="kb-footer" id="kb-footer">-->
<!--      <div class="footer-row footer-component">-->
<!--        <div class="footer-copyright">-->
<!--          <p class="copyright">Copyright (C) KB E-HRD. All Rights Reserved</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </footer>-->
</template>
<script>
import {computed, reactive, ref} from 'vue';
import {useStore} from 'vuex';
import {ACT_MLOGIN_SIGNIN_ADMIN_AUTH, TOKEN_KEY} from '@/store/modules/auth/auth';
import {isSuccess,getResult} from '@/assets/js/util';
import {useAlert} from '@/assets/js/modules/common/alert';
import {saveToken} from '@/assets/js/localstorage.service';
import {useRoute, useRouter} from 'vue-router';
import ApiService from '@/assets/js/api.service';
import {setInside} from '@/assets/js/modules/auth/auth-common.js'

export default {
  name: 'Login',
  components: {},
  setup() {
    const store = useStore();
    const isReady = ref(false);
    const isMobile = computed(() => store.state.auth.isMobile);
    const isLoading = ref(false);
    const certFlag = ref(true);
    const promiFlag = ref(false);
    const {showMessage} = useAlert();

    const info = reactive({
      lrnerId: '',
      adminId: '',
    });



    const validate = () => {
      let result = false;
      isLoading.value = false;

      if(!info.lrnerId) {
        showMessage('접속대상 직원번호를 입력해주세요.');
        return false;
      }

      if(!info.adminId){
        showMessage('접속하시는 운영자 직원번호를 입력해주세요.');
        return false;
      }

      result = true;
      return result;
    }

    const route = useRoute();
    const router = useRouter();

    const enterAdminId = () =>{
      document.getElementById("enterAdminIdEle").focus();
    }

    const adminChk =()=>{ //Admin
      ApiService.post('/v1/app/ips/adm', {mngChk:'A'}).then(res => {
        if(getResult(res).number === 500) {
          // isReady.value = true;
          router.push({path:'/signin'});
          //location.href='/app/signin';
        }else{
          isReady.value = true;
        }
      })
    }


    if( !store.state.auth.isInside) { //내부망체크 그대로
      ApiService.post('/v1/app/ips/mngs', {}).then(res => {
        if(isSuccess(res)) {
          setInside(1)
        }
      })
    }
    adminChk();


    const login = () => {
      if (isLoading.value) return;
      isLoading.value = true;

       if(validate()) {
         let admAuth = {
           mngrId : info.adminId,
           mngrNm : info.lrnerId,
           mngrSn : -1
         }
        store.dispatch(`auth/${ACT_MLOGIN_SIGNIN_ADMIN_AUTH}`, admAuth).then(res => {
          if(isSuccess(res)) {
            saveToken(TOKEN_KEY, res.authToken);
            if (route.query.redirect && !route.query.redirect.includes('/signin') && !route.query.redirect.includes('/login')) {
              router.push({path: route.query.redirect});
            }else {
              router.push('/main');
            }
          } else {
            showMessage('접속 대상직원 번호 또는 국민인증서 인증여부를 <br/>다시 확인하시고 처음부터 다시 시작 해 주세요', ()=>{
              location.reload();
            });
          }
          isLoading.value = false;
        }).catch(() => {
          alert('error');
          isLoading.value = false;
        });
      }

    };


    return {
      isMobile,
      isLoading,
      info,
      isReady,
      login,
      enterAdminId,
      certFlag,
      promiFlag
    };
  },
};
</script>
